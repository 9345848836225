<template>
<div class="report" style="margin:20px">
    <a-row>
        <div class="s-r-font1">스토어링크 리포트</div>
        <a-col :span="span1">
            <div class="s-r-b1" v-bind:class="{ mobile: _mobile }">
                <div v-if="selectedApplyData.status">
                    <div>
                        <div v-if="selectedApplyData.status=='A'">
                            <a-tag color="red">지원[미결제]</a-tag>
                        </div>
                        <div v-if="selectedApplyData.status=='C'">
                            <a-tag color="orange">결제완료</a-tag>
                        </div>
                        <div v-if="selectedApplyData.status=='E'">
                            <a-tag color="green">이벤트지원</a-tag>
                        </div>
                        <div v-if="selectedApplyData.status=='F'">
                            <a-tag color="cyan">승인중</a-tag>
                        </div>
                        <div v-if="selectedApplyData.status=='W'">
                            <a-tag color="blue">오픈대기</a-tag>
                        </div>
                        <div v-if="selectedApplyData.status=='P'">
                            <a-tag color="purple">진행중</a-tag>
                        </div>
                        <div v-if="selectedApplyData.status=='B'">
                            <a-tag color="purple">진행중</a-tag>
                        </div>
                        <div v-if="selectedApplyData.status=='T'">
                            <a-tag color="purple">진행중</a-tag>
                        </div>
                        <div v-if="selectedApplyData.status=='X'">
                            <a-tag color="#108ee9">캠페인완료</a-tag>
                        </div>
                    </div>
                    <div class="s-r-b3">
                        <a :href="selectedApplyData.url" target="_blank"><span>제품 바로 가기</span></a>
                    </div>

                    <div class="s-r-font2" style="margin-top:6px">{{selectedApplyData.keyword}}</div>
                    <div class="s-r-font3">{{selectedApplyData.sname}}&nbsp;[{{selectedApplyData.storetype}}]</div>
                    <div class="s-r-font4">캠페인 번호 &nbsp; <span>{{selectedApplyData.order}}</span></div>
                    <div class="s-r-font5" style="margin-bottom:10px">지원 일시 : &nbsp; {{selectedApplyData.eventdttm}}</div>
                    <a-divider style="margin-top:0px" />
                    <div style="margin-top:-10px;margin-bottom:10px;">
                        <a-row>
                            <a-col :span="8">
                                <span class="s-r-font5">{{getStore().c1}}&nbsp;</span><span class="s-r-font6 ">{{selectedApplyData.n1}}/{{selectedApplyData.c1}}&nbsp;&nbsp;</span>
                            </a-col>
                            <a-col :span="8" v-if="selectedApplyData['c2']">
                                <span class="s-r-font5">{{getStore().c2}}&nbsp;</span><span class="s-r-font6" v-if="selectedApplyData['c2']">{{selectedApplyData.n2}}/{{selectedApplyData.c2}}&nbsp;&nbsp;</span>
                            </a-col>
                            <a-col :span="8" v-if="selectedApplyData['c4']">
                                <span class="s-r-font5">{{getStore().c4}}&nbsp;</span><span class="s-r-font6" v-if="selectedApplyData['c4']">{{selectedApplyData.n4}}/{{selectedApplyData.c4}}</span>
                            </a-col>
                            <a-col :span="8" v-if="selectedApplyData['c3']">
                                <span class="s-r-font5">{{getStore().c3}}&nbsp;</span><span class="s-r-font6" v-if="selectedApplyData['c3']">{{selectedApplyData.n3}}/{{selectedApplyData.c3}}&nbsp;&nbsp;</span>
                            </a-col>
                            <a-col :span="8" v-if="selectedApplyData['c8']">
                                <span class="s-r-font5">{{getStore().c8}}&nbsp;</span><span class="s-r-font6" v-if="selectedApplyData['c8']">{{selectedApplyData.n8}}/{{selectedApplyData.c8}}&nbsp;&nbsp;</span>
                            </a-col>
                            <a-col :span="8" v-if="selectedApplyData['c5']">
                                <span class="s-r-font5">{{getStore().c5}}&nbsp;</span><span class="s-r-font6" v-if="selectedApplyData['c5']">{{selectedApplyData.n5}}/{{selectedApplyData.c5}}&nbsp;&nbsp;</span>
                            </a-col>
                            <a-col :span="8" v-if="selectedApplyData['c9']">
                                <span class="s-r-font5">{{getStore().c9}}&nbsp;</span><span class="s-r-font6" v-if="selectedApplyData['c9']">{{selectedApplyData.n5}}/{{selectedApplyData.c9}}&nbsp;&nbsp;</span>
                            </a-col>
                            <a-col :span="8" v-if="selectedApplyData['c7']">
                                <span class="s-r-font5">인스타그램</span>&nbsp;<span class="s-r-font6" v-if="selectedApplyData['c7']">{{selectedApplyData.n7}}/{{selectedApplyData.c7 ? selectedApplyData.c7 : 0}}&nbsp;&nbsp;</span>
                            </a-col>
                            <a-col :span="8" v-if="selectedApplyData['c6']">
                                <span class="s-r-font5">네이버블로그</span>&nbsp;<span class="s-r-font6" v-if="selectedApplyData['c6']">{{selectedApplyData.n6}}/{{selectedApplyData.c6 ? selectedApplyData.c6 : 0}}&nbsp;&nbsp;</span>
                            </a-col>
                        </a-row>

                    </div>
                    <a-divider style="margin-top:0px" />
                    <div style="margin-top:-10px" v-if="loadingtbl==false">
                        <div class="s-r-font7">미완료</div>

                        <a-row>
                            <a-col :span="8" v-if="getR1()">
                                <span class="s-r-font5">{{getStore().c1}}&nbsp;</span><span v-if="getR1()" class="s-r-font8 ">{{getR1()}}&nbsp;&nbsp;</span>
                            </a-col>
                            <a-col :span="8" v-if="getR2()&&selectedApplyData['c2']">
                                <span class="s-r-font5">{{getStore().c2}}&nbsp;</span><span v-if="getR2()&&selectedApplyData['c2']" class="s-r-font8 ">{{getR2()}}&nbsp;&nbsp;</span>
                            </a-col>
                            <a-col :span="8" v-if="getR4()">
                                <span class="s-r-font5">{{getStore().c4}}&nbsp;</span><span v-if="getR4()" class="s-r-font8 ">{{getR4()}}&nbsp;&nbsp;</span>
                            </a-col>
                            <a-col :span="8" v-if="getR3()&&selectedApplyData['c3']">
                                <span class="s-r-font5">{{getStore().c3}}&nbsp;</span><span v-if="getR3()&&selectedApplyData['c3']" class="s-r-font8 ">{{getR3()}}&nbsp;&nbsp;</span>
                            </a-col>
                            <a-col :span="8" v-if="getR8()&&selectedApplyData['c8']">
                                <span class="s-r-font5">{{getStore().c8}}&nbsp;</span><span v-if="getR8()&&selectedApplyData['c8']" class="s-r-font8 ">{{getR8()}}&nbsp;&nbsp;</span>
                            </a-col>
                            <a-col :span="8" v-if="getR5()&&selectedApplyData['c5']">
                                <span class="s-r-font5">{{getStore().c5}}&nbsp;</span><span v-if="getR5()&&selectedApplyData['c5']" class="s-r-font8 ">{{getR5()}}&nbsp;&nbsp;</span>
                            </a-col>
                            <a-col :span="8" v-if="getR9()&&selectedApplyData['c9']">
                                <span class="s-r-font5">{{getStore().c9}}&nbsp;</span><span v-if="getR9()&&selectedApplyData['c9']" class="s-r-font8 ">{{getR9()}}&nbsp;&nbsp;</span>
                            </a-col>
                            <a-col :span="8" v-if="getR6()&&selectedApplyData['c6']">
                                <span class="s-r-font5">인스타그램&nbsp;</span><span v-if="getR6()&&selectedApplyData['c6']" class="s-r-font8 ">{{getR7()}}&nbsp;&nbsp;</span>
                            </a-col>
                            <a-col :span="8" v-if="getR7()&&selectedApplyData['c7']">
                                <span class="s-r-font5">네이버블로그&nbsp;</span><span v-if="getR7()&&selectedApplyData['c7']" class="s-r-font8 ">{{getR6()}}</span>
                            </a-col>
                        </a-row>

                    </div>
                </div>
            </div>
        </a-col>
        <a-col :span="span2">
            <div class="s-r-b2" style="padding:20px">
                <a-button @click="onExportExcelByCampaign()" :disabled="campaigndata1.length==0"><i class="fal fa-file-excel"></i><span style="padding-left:10px">진행결과 엑셀 다운로드 하기</span></a-button>
                <a-tabs v-model="activeTabName">
                    <a-tab-pane tab="진행결과" key="first">
                        <a-table :loading="loadingtbl" :data-source="campaigndata1" :columns="columns" style="width: 100%;height:100%" :row-key="record => record.id" :scroll="{ x: 300 }">
                            <div slot="c1" slot-scope="record" v-if="selectedApplyData['c1']">
                                <div v-if="record.c1=='완료'">
                                    <span class="s-r-font9"><i class="fal fa-check">&nbsp;&nbsp;</i> <span>완료</span></span><br />
                                    <span class="s-r-font10">{{record.c1date}}</span>
                                </div>
                                <div v-if="record.c1=='미완료'">
                                    <span class="s-r-font11"> <i class="fal fa-times">&nbsp;&nbsp;</i>미완료</span>
                                </div>
                                <div v-if="record.c1=='-'">
                                    -
                                </div>
                            </div>
                            <div slot="c2" slot-scope="record" v-if="selectedApplyData['c2']">
                                <div v-if="record.c2=='완료'">
                                    <span class="s-r-font9"><i class="fal fa-check">&nbsp;&nbsp;</i> <span>완료</span></span><br />
                                    <span class="s-r-font10">{{record.c2date}}</span>
                                </div>
                                <div v-if="record.c2=='미완료'">
                                    <span class="s-r-font11"> <i class="fal fa-times">&nbsp;&nbsp;</i>미완료</span>
                                </div>
                                <div v-if="record.c2=='-'">
                                    -
                                </div>
                            </div>
                            <div slot="c3" slot-scope="record" v-if="selectedApplyData['c3']">
                                <div v-if="record.c3=='완료'">
                                    <span class="s-r-font9"><i class="fal fa-check">&nbsp;&nbsp;</i> <span>완료</span></span><br />
                                    <span class="s-r-font10">{{record.c3date}}</span>
                                </div>
                                <div v-if="record.c3=='미완료'">
                                    <span class="s-r-font11"> <i class="fal fa-times">&nbsp;&nbsp;</i>미완료</span>
                                </div>
                                <div v-if="record.c3=='-'">
                                    -
                                </div>
                            </div>
                            <div slot="c4" slot-scope="record" v-if="selectedApplyData['c4']">

                                <div v-if="record.c4=='완료'">
                                    <span class="s-r-font9"><i class="fal fa-check">&nbsp;&nbsp;</i> <span>완료</span></span><br />
                                    <span class="s-r-font10">{{record.c4date}}</span>
                                </div>
                                <div v-if="record.c4=='미완료'">
                                    <span class="s-r-font11"> <i class="fal fa-times">&nbsp;&nbsp;</i>미완료</span>
                                </div>
                                <div v-if="record.c4=='-'">
                                    -
                                </div>
                            </div>

                            <div slot="c5" slot-scope="record" v-if="selectedApplyData['c5']">
                                <div v-if="record.c5=='완료'">
                                    <span class="s-r-font9"><i class="fal fa-check">&nbsp;&nbsp;</i> <span>완료</span></span><br />
                                    <span class="s-r-font10">{{record.c5date}}</span>
                                </div>
                                <div v-if="record.c5=='미완료'">
                                    <span class="s-r-font11"> <i class="fal fa-times">&nbsp;&nbsp;</i>미완료</span>
                                </div>
                                <div v-if="record.c5=='-'">
                                    -
                                </div>
                            </div>
                            <div slot="c8" slot-scope="record" v-if="selectedApplyData['c8']">

                                <div v-if="record.c8=='완료'">
                                    <span class="s-r-font9"><i class="fal fa-check">&nbsp;&nbsp;</i> <span>완료</span></span><br />
                                    <span class="s-r-font10">{{record.c8date}}</span>
                                </div>
                                <div v-if="record.c8=='미완료'">
                                    <span class="s-r-font11"> <i class="fal fa-times">&nbsp;&nbsp;</i>미완료</span>
                                </div>
                                <div v-if="record.c8=='-'">
                                    -
                                </div>
                            </div>
                            <div slot="c9" slot-scope="record" v-if="selectedApplyData['c9']">
                                <div v-if="record.c5=='완료'">
                                    <span class="s-r-font9"><i class="fal fa-check">&nbsp;&nbsp;</i> <span>완료</span></span><br />
                                    <span class="s-r-font10">{{record.c5date}}</span>
                                </div>
                                <div v-if="record.c5=='미완료'">
                                    <span class="s-r-font11"> <i class="fal fa-times">&nbsp;&nbsp;</i>미완료</span>
                                </div>
                                <div v-if="record.c5=='-'">
                                    -
                                </div>
                            </div>
                            <div slot="c7" slot-scope="record" v-if="selectedApplyData['c7']">

                                <div v-if="record.c7=='완료'">
                                    <span class="s-r-font9"><i class="fal fa-check">&nbsp;&nbsp;</i> <span>완료</span></span><br />
                                    <span class="s-r-font10">{{record.c7date}}</span>
                                </div>
                                <div v-if="record.c7=='미완료'">
                                    <span class="s-r-font11"> <i class="fal fa-times">&nbsp;&nbsp;</i>미완료</span>
                                </div>
                                <div v-if="record.c7=='-'">
                                    -
                                </div>

                            </div>
                            <div slot="c6" slot-scope="record" v-if="selectedApplyData['c6']">

                                <div v-if="record.c6=='완료'">
                                    <span class="s-r-font9"><i class="fal fa-check">&nbsp;&nbsp;</i> <span>완료</span></span><br />
                                    <span class="s-r-font10">{{record.c6date}}</span>
                                </div>
                                <div v-if="record.c6=='미완료'">
                                    <span class="s-r-font11"> <i class="fal fa-times">&nbsp;&nbsp;</i>미완료</span>
                                </div>
                                <div v-if="record.c6=='-'">
                                    -
                                </div>
                            </div>

                        </a-table>
                    </a-tab-pane>
                    <a-tab-pane tab="결과분석" key="second" style="overflow-x:scroll">
                        <div style="min-width:500px;" v-if="selectedApplyData.storetype">
                            <apexchart width="100%" height="500" type="area" :options="chartOptions" :series="chartRawData"></apexchart>
                            <div class="s-r-font12" style="margin-bottom:20px;margin-top:20px">
                                지표별 완료 추이
                            </div>
                            <a-row>
                                <a-col :span="8">
                                    <div style="width:90%">
                                        <span class="s-r-font5">{{getStore().c1}}&nbsp;</span><span class="s-r-font6 ">{{selectedApplyData.n1}}/{{selectedApplyData.c1}}</span>
                                        <a-progress :percent="getR1Ratio()" :show-info="false" strokeColor="#0264fb" />
                                    </div>
                                </a-col>
                                <a-col :span="8" v-if="selectedApplyData['c2']">
                                    <div style="width:90%">
                                        <span class="s-r-font5">{{getStore().c2}}&nbsp;</span><span class="s-r-font6 ">{{selectedApplyData.n2}}/{{selectedApplyData.c2}}</span>
                                        <a-progress :percent="getR2Ratio()" :show-info="false" strokeColor="#0264fb" />
                                    </div>
                                </a-col>
                                <a-col :span="8" v-if="selectedApplyData['c4']">
                                    <div style="width:90%">
                                        <span class="s-r-font5">{{getStore().c4}}&nbsp;</span><span class="s-r-font6 ">{{selectedApplyData.n4}}/{{selectedApplyData.c4}}</span>
                                        <a-progress :percent="getR4Ratio()" :show-info="false" strokeColor="#0264fb" />
                                    </div>
                                </a-col>
                                <a-col :span="8" v-if="selectedApplyData['c3']">
                                    <div style="width:90%">
                                        <span class="s-r-font5">{{getStore().c3}}&nbsp;</span><span class="s-r-font6 ">{{selectedApplyData.n3}}/{{selectedApplyData.c3}}</span>
                                        <a-progress :percent="getR3Ratio()" :show-info="false" strokeColor="#0264fb" />
                                    </div>
                                </a-col>
                                <a-col :span="8" v-if="selectedApplyData['c8']">
                                    <div style="width:90%">
                                        <span class="s-r-font5">{{getStore().c8}}&nbsp;</span><span class="s-r-font6 ">{{selectedApplyData.n8}}/{{selectedApplyData.c8}}</span>
                                        <a-progress :percent="getR8Ratio()" :show-info="false" strokeColor="#0264fb" />
                                    </div>
                                </a-col>
                                <a-col :span="8" v-if="selectedApplyData['c5']">
                                    <div style="width:90%">
                                        <span class="s-r-font5">{{getStore().c5}}&nbsp;</span><span class="s-r-font6 ">{{selectedApplyData.n5}}/{{selectedApplyData.c5}}</span>
                                        <a-progress :percent="getR5Ratio()" :show-info="false" strokeColor="#0264fb" />
                                    </div>
                                </a-col>
                                <a-col :span="8" v-if="selectedApplyData['c9']">
                                    <div style="width:90%">
                                        <span class="s-r-font5">{{getStore().c9}}&nbsp;</span><span class="s-r-font6 ">{{selectedApplyData.n5}}/{{selectedApplyData.c9}}</span>
                                        <a-progress :percent="getR9Ratio()" :show-info="false" strokeColor="#0264fb" />
                                    </div>
                                </a-col>
                                <a-col :span="8" v-if="selectedApplyData['c7']">
                                    <div style="width:90%">
                                        <span class="s-r-font5">인스타그램</span>&nbsp;<span class="s-r-font6 ">{{selectedApplyData.n7}}/{{selectedApplyData.c7 ? selectedApplyData.c7 : 0}}</span>
                                        <a-progress :percent="getR7Ratio()" :show-info="false" strokeColor="#0264fb" />
                                    </div>
                                </a-col>
                                <a-col :span="8" v-if="selectedApplyData['c6']">
                                    <div style="width:90%">
                                        <span class="s-r-font5">네이버블로그</span>&nbsp;<span class="s-r-font6 ">{{selectedApplyData.n6}}/{{selectedApplyData.c6 ? selectedApplyData.c6 : 0}}</span>
                                        <a-progress :percent="getR6Ratio()" :show-info="false" strokeColor="#0264fb" />
                                    </div>
                                </a-col>

                            </a-row>

                        </div>
                    </a-tab-pane>
                    <a-tab-pane tab="지원내용" key="third" style="overflow-x:scroll">
                        <a-col :span="24" style="min-width:500px;" v-if="selectedApplyData.storetype">
                            <a-row style="padding:10px;background-color:#fafafa;margin-top:20px">
                                <a-col :span="4">
                                    지표
                                </a-col>
                                <a-col :span="4">
                                    신청수
                                </a-col>
                                <a-col :span="4">
                                    제품비용
                                </a-col>
                                <a-col :span="4">
                                    서비스비용
                                </a-col>
                                <a-col :span="4">
                                    개당비용<br />(제품+서비스비용)
                                </a-col>
                                <a-col :span="4">
                                    총가격(신청수X비용)
                                </a-col>
                            </a-row>
                            <a-row style="padding:10px;border-bottom:1px solid #eaeaea">
                                <a-col :span="4">
                                    {{getStore().c1}}
                                </a-col>
                                <a-col :span="4">
                                    {{selectedApplyData.c1}}개
                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(selectedApplyData.price+selectedApplyData.dprice).toLocaleString()}}원
                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(buypay).toLocaleString()}}원
                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(selectedApplyData.price+selectedApplyData.dprice+parseInt(buypay) ).toLocaleString()}}원
                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(selectedApplyData.p1).toLocaleString()}}원
                                </a-col>
                            </a-row>

                            <a-row style="padding:10px;border-bottom:1px solid #eaeaea" v-if="selectedApplyData.c2">
                                <a-col :span="4">
                                    {{getStore().c2}}
                                </a-col>
                                <a-col :span="4">
                                    {{selectedApplyData.c2}}개
                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(textpay).toLocaleString()}}원
                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(textpay).toLocaleString()}}원
                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(selectedApplyData.p2).toLocaleString()}}원
                                </a-col>
                            </a-row>
                            <a-row style="padding:10px;border-bottom:1px solid #eaeaea" v-if="selectedApplyData.c3">
                                <a-col :span="4">
                                    {{getStore().c3}}
                                </a-col>
                                <a-col :span="4">
                                    {{selectedApplyData.c3}}개
                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(photopay).toLocaleString()}}원
                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(photopay).toLocaleString()}}원
                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(selectedApplyData.p3).toLocaleString()}}원
                                </a-col>
                            </a-row>
                            <a-row style="padding:10px;border-bottom:1px solid #eaeaea" v-if="selectedApplyData.c8">
                                <a-col :span="4">
                                    {{getStore().c8}}
                                </a-col>
                                <a-col :span="4">
                                    {{selectedApplyData.c8}}개
                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(videopay).toLocaleString()}}원
                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(videopay).toLocaleString()}}원
                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(selectedApplyData.p8).toLocaleString()}}원
                                </a-col>
                            </a-row>
                            <a-row style="padding:10px;border-bottom:1px solid #eaeaea" v-if="selectedApplyData.c4">

                                <a-col :span="4">
                                    {{getStore().c4}}
                                </a-col>
                                <a-col :span="4">
                                    {{selectedApplyData.c4}}개
                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(zzimpay).toLocaleString()}}원
                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(zzimpay).toLocaleString()}}원
                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(selectedApplyData.p4).toLocaleString()}}원
                                </a-col>
                            </a-row>
                            <a-row style="padding:10px;border-bottom:1px solid #eaeaea" v-if="selectedApplyData.c5">
                                <a-col :span="4">
                                    {{getStore().c5}}
                                </a-col>
                                <a-col :span="4">
                                    {{selectedApplyData.c5}}개
                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(qapay).toLocaleString()}}원
                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(qapay).toLocaleString()}}원
                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(selectedApplyData.p5).toLocaleString()}}원
                                </a-col>
                            </a-row>
                            <a-row style="padding:10px;border-bottom:1px solid #eaeaea" v-if="selectedApplyData.c9">
                                <a-col :span="4">
                                    회원가입
                                </a-col>
                                <a-col :span="4">
                                    {{selectedApplyData.c9}}개
                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(zzimpay).toLocaleString()}}원
                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(zzimpay).toLocaleString()}}원
                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(selectedApplyData.p9).toLocaleString()}}원
                                </a-col>
                            </a-row>
                            <a-row style="padding:10px;border-bottom:1px solid #eaeaea" v-if="selectedApplyData.c6">
                                <a-col :span="4">
                                    네이버 블로그
                                </a-col>
                                <a-col :span="4">
                                    {{selectedApplyData.c6}}개
                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">
                                    10,000원
                                </a-col>
                                <a-col :span="4">
                                    10,000원
                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(selectedApplyData.p6).toLocaleString()}}원
                                </a-col>
                            </a-row>
                            <a-row style="padding:10px;border-bottom:1px solid #eaeaea" v-if="selectedApplyData.c7">
                                <a-col :span="4">
                                    인스타그램
                                </a-col>
                                <a-col :span="4">
                                    {{selectedApplyData.c7}}개
                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">
                                    5,000원
                                </a-col>
                                <a-col :span="4">
                                    5,000원
                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(selectedApplyData.p7).toLocaleString()}}원
                                </a-col>
                            </a-row>
                            <a-row style="padding:10px;border-bottom:1px solid #eaeaea">
                                <a-col :span="4">
                                    총계
                                </a-col>
                                <a-col :span="4">
                                    {{selectedApplyData.c1}}개
                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(selectedApplyData.original).toLocaleString()}}원
                                </a-col>
                            </a-row>
                            <a-row style="padding:10px;border-bottom:1px solid #eaeaea">
                                <a-col :span="4">
                                    예치금
                                </a-col>
                                <a-col :span="4">
                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(selectedApplyData.deposit).toLocaleString()}}원
                                </a-col>
                            </a-row>
                            <a-row style="padding:10px;border-bottom:1px solid #eaeaea">
                                <a-col :span="4">
                                    쿠폰
                                </a-col>
                                <a-col :span="4">
                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">
                                    {{selectedApplyData.couponPay}}원
                                </a-col>
                            </a-row>

                            <a-row style="padding:10px;border-bottom:1px solid #eaeaea">
                                <a-col :span="4">
                                    총합
                                </a-col>
                                <a-col :span="4">
                                    총계-쿠폰
                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(selectedApplyData.tp).toLocaleString()}}원
                                </a-col>
                            </a-row>
                            <a-row style="padding:10px;border-bottom:1px solid #eaeaea">
                                <a-col :span="4">
                                    최종
                                </a-col>
                                <a-col :span="4">
                                    총합(VAT)-예치금
                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">

                                </a-col>
                                <a-col :span="4">
                                    {{parseInt(selectedApplyData.tp2).toLocaleString()}}원
                                </a-col>
                            </a-row>

                        </a-col>

                    </a-tab-pane>

                </a-tabs>

            </div>
        </a-col>
    </a-row>
</div>
</template>

<script>
Date.prototype.addHours = function(h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};

var allCampaign = null;
import firebase from "firebase";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import {
  replaceAll,
  getCurrentDate2,
  getCurrentTime,
  isMobile,
  ShoppingMall
} from "../components/fnc.js";
export default {
  data() {
    return {
      span1: 5,
      span2: 19,
      sam: [],
      getIsAgency: "-",
      ShoppingMall: ShoppingMall,
      storelist: [
        {
          value: "전체",
          label: "전체"
        },
        {
          value: ShoppingMall.NAVER_STORE,
          label: ShoppingMall.NAVER_STORE,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-naver.png?alt=media&token=8f71b90e-e771-46ae-825b-4111706156a7"
        },
        {
          value: ShoppingMall.NAVER_WINDOW,
          label: ShoppingMall.NAVER_WINDOW,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-naver-beauty%20(1).png?alt=media&token=fecfc02e-3890-41de-a763-62421e6b0afa"
        },
        {
          value: ShoppingMall.NAVER_CROWD,
          label: ShoppingMall.NAVER_CROWD,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-naver.png?alt=media&token=8f71b90e-e771-46ae-825b-4111706156a7"
        },
        {
          value: ShoppingMall.NAVER_PLACE,
          label: ShoppingMall.NAVER_PLACE,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-place.png?alt=media&token=4c41313a-f501-477a-8f0c-8474ace3b4b2"
        },
        {
          value: ShoppingMall.COUPANG,
          label: ShoppingMall.COUPANG,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-coupang.png?alt=media&token=8f9fcc4a-911c-4e8a-8b3b-b9c82ae8cd8c"
        },
        {
          value: ShoppingMall.KAKAO,
          label: ShoppingMall.KAKAO,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-kakao.png?alt=media&token=c1404cd1-bf08-4594-9f4a-e70751c3e31d"
        },
        {
          value: ShoppingMall.TALK,
          label: ShoppingMall.TALK,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-talk.png?alt=media&token=9343a52b-1c9f-42b6-9ef2-7f4ba294d8f1"
        },
        {
          value: ShoppingMall.CJ,
          label: ShoppingMall.CJ,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-cj.png?alt=media&token=c73bd252-1cdd-4b7a-a13e-e22748c19bcc"
        },
        {
          value: ShoppingMall.ELEVEN,
          label: ShoppingMall.ELEVEN,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-11.png?alt=media&token=d9a1f10f-805b-4ada-9367-906f47240691"
        },
        {
          value: ShoppingMall.GMARKET,
          label: ShoppingMall.GMARKET,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-g.png?alt=media&token=dd336c8d-b45d-490b-adde-cd783224e831"
        },
        {
          value: ShoppingMall.AUCTION,
          label: ShoppingMall.AUCTION,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-a.png?alt=media&token=5f055dfa-71f7-49ba-97d5-6cfb5968d9a0"
        },
        {
          value: ShoppingMall.GSSHOP,
          label: ShoppingMall.GSSHOP,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-gsshop.png?alt=media&token=fa83709c-3176-4728-9f26-114156134b8a"
        },
        {
          value: ShoppingMall.TMON,
          label: ShoppingMall.TMON,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-tmon.png?alt=media&token=e94b55ad-6ad9-46aa-9ea6-4907667ad368"
        },
        {
          value: ShoppingMall.WEMAFE,
          label: ShoppingMall.WEMAFE,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-wemafe.png?alt=media&token=91b750f8-8fd1-4089-b9d9-d0408350495c"
        },
        {
          value: ShoppingMall.WADIZ,
          label: ShoppingMall.WADIZ,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-wadiz.png?alt=media&token=bd4ac3b0-816c-41cf-9ecb-df871be02de2"
        },
        {
          value: ShoppingMall.BAEMIN,
          label: ShoppingMall.BAEMIN,
          src:
            "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-bamin.png?alt=media&token=4b64aaaa-2ea6-4245-ac84-4413b97805b7"
        }
      ],
      activeTabName: "first",
      baseKeyword: "",
      agency: [],
      chartRawData: [],
      autoHistory: [],
      autoHistoryNaver: [],
      autoHistoryCoupang: [],
      autoHistoryTmon: [],
      keyword: "",
      store: "",
      selectedApplyData: {
        status: "",
        keyword: "",
        eventdttm: "",
        sname: "",
        order: "",
        desc: "",
        price: "",
        dprice: "",
        storetype: "",
        c1: null,
        c2: null,
        c3: null,
        c4: null,
        c5: null,
        c6: null,
        c7: null,
        c8: null,
        c9: null,
        n1: 0,
        n2: 0,
        n3: 0,
        n4: 0,
        n5: 0,
        n6: 0,
        n7: 0,
        n8: 0,
        n9: 0,
        sn: "",
        ref: "",
        agency: "",
        p1: 0,
        p2: 0,
        p3: 0,
        p4: 0,
        p5: 0,
        p6: 0,
        p7: 0,
        p8: 0,
        p9: 0,
        tp: 0,
        tp2: 0,
        original: "",
        deposit: "",
        couponQty: "",
        couponPay: "",
        buypay: null,
        textpay: null,
        photopay: null,
        videopay: null,
        zzimpay: null,
        qapay: null,
        regpay: null,
        naverpay: "10000",
        instagrampay: "5000"
      },
      activeTab: "1",
      campaigndata1: [],
      campaignId: "",
      stack: 0,
      loadingtbl: false,
      chartData: [],
      chartOptions: {
        chart: {
          locales: [
            {
              name: "ko",
              options: {
                months: [
                  "1월",
                  "2월",
                  "3월",
                  "4월",
                  "5월",
                  "6월",
                  "7월",
                  "8월",
                  "9월",
                  "10월",
                  "11월",
                  "12월"
                ],
                shortMonths: [
                  "'1",
                  "'2",
                  "'3",
                  "'4",
                  "'5",
                  "'6",
                  "'7",
                  "'8",
                  "'9",
                  "'10",
                  "'11",
                  "'12"
                ],
                days: [
                  "일요일",
                  "월요일",
                  "화요일",
                  "수요일",
                  "목요일",
                  "금요일",
                  "토요일"
                ],
                shortDays: ["일", "월", "화", "수", "목", "금", "토"],
                toolbar: {
                  exportToSVG: "SVG 다운로드",
                  exportToPNG: "PNG 다운로드",
                  menu: "메뉴",
                  selection: "선택",
                  selectionZoom: "선택 확대",
                  zoomIn: "줌인",
                  zoomOut: "줌아웃",
                  pan: "팬",
                  reset: "줌초기화"
                }
              }
            }
          ],
          defaultLocale: "ko",
          shadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1
          },
          toolbar: {
            show: false
          }
        },
        // colors: [
        //     "#6cd6aa",
        //     "#f28caf",
        //     "#cb50e9",
        //     "#727dd6",
        //     "#aba9a9",
        //     "#f6db5f",
        //     "#ffb554",
        //     "#fe5e51",
        //     "#9e3d64",
        //     "#36abb5"
        // ],
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: "smooth"
        },
        title: {
          text: "스토어 순위",
          align: "left"
        },
        // grid: {
        //     borderColor: "#e7e7e7",
        //     row: {
        //         colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        //         opacity: 0.5
        //     }
        // },
        markers: {
          size: 6
        },
        xaxis: {
          type: "datetime",
          categories: [],
          labels: {
            formatter: function(value) {
              return getCurrentDate2(new Date(value));
            }
          }
        },
        yaxis: {
          reversed: true,
          title: {
            text: "렝킹"
          },
          min: 0,
          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              cssClass: "apexcharts-yaxis-label"
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            formatter: value => {
              return parseInt(value);
            }
          }
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: 0,
          offsetX: 0
        }
      },
      columns: []
    };
  },
  computed: {
    _mobile: function() {
      var _mobile = this.$vssWidth < 1024 ? true : isMobile();
      this.span1 = _mobile ? 24 : 7;
      this.span2 = _mobile ? 24 : 17;
      return _mobile;
    }
  },
  methods: {
    getStore() {
      var _r = this.storelist.find(
        item => item.value == this.selectedApplyData["storetype"]
      );
      if (_r) {
        return _r["missionTitle"];
      } else {
        return this.storelist[0]["missionTitle"];
      }
    },
    getRCalculate(c1, n1) {
      var _r = c1 - n1;
      return _r > 0 ? _r : 0;
    },
    getR1() {
      return this.getRCalculate(
        this.selectedApplyData.c1,
        this.selectedApplyData.n1
      );
    },
    getR2() {
      return this.getRCalculate(
        this.selectedApplyData.c2,
        this.selectedApplyData.n2
      );
    },
    getR3() {
      return this.getRCalculate(
        this.selectedApplyData.c3,
        this.selectedApplyData.n3
      );
    },
    getR4() {
      return this.getRCalculate(
        this.selectedApplyData.c4,
        this.selectedApplyData.n4
      );
    },
    getR8() {
      return this.getRCalculate(
        this.selectedApplyData.c8,
        this.selectedApplyData.n8
      );
    },
    getR5() {
      return this.getRCalculate(
        this.selectedApplyData.c5,
        this.selectedApplyData.n5
      );
    },
    getR6() {
      return this.getRCalculate(
        this.selectedApplyData.c6,
        this.selectedApplyData.n6
      );
    },
    getR7() {
      return this.getRCalculate(
        this.selectedApplyData.c7,
        this.selectedApplyData.n7
      );
    },
    getR9() {
      return this.getRCalculate(
        this.selectedApplyData.c9,
        this.selectedApplyData.n5
      );
    },
    getRatioCalculate(c1, n1) {
      if (c1 > 0) {
        if (c1 <= n1) {
          return 100;
        } else {
          return parseInt(n1 / c1 * 100);
        }
      } else {
        return 0;
      }
    },
    getR1Ratio() {
      return this.getRatioCalculate(
        this.selectedApplyData.c1,
        this.selectedApplyData.n1
      );
    },
    getR2Ratio() {
      return this.getRatioCalculate(
        this.selectedApplyData.c2,
        this.selectedApplyData.n2
      );
    },
    getR3Ratio() {
      return this.getRatioCalculate(
        this.selectedApplyData.c3,
        this.selectedApplyData.n3
      );
    },
    getR4Ratio() {
      return this.getRatioCalculate(
        this.selectedApplyData.c4,
        this.selectedApplyData.n4
      );
    },
    getR8Ratio() {
      return this.getRatioCalculate(
        this.selectedApplyData.c8,
        this.selectedApplyData.n8
      );
    },
    getR5Ratio() {
      return this.getRatioCalculate(
        this.selectedApplyData.c5,
        this.selectedApplyData.n5
      );
    },
    getR6Ratio() {
      return this.getRatioCalculate(
        this.selectedApplyData.c6,
        this.selectedApplyData.n6
      );
    },
    getR7Ratio() {
      return this.getRatioCalculate(
        this.selectedApplyData.c7,
        this.selectedApplyData.n7
      );
    },
    getR9Ratio() {
      return this.getRatioCalculate(
        this.selectedApplyData.c9,
        this.selectedApplyData.n5
      );
    },
    getValue1() {
      return this.selectedApplyData.n1 / this.selectedApplyData.c1 * 100;
    },
    getValue2() {
      return this.selectedApplyData.n2 / this.selectedApplyData.c2 * 100;
    },
    getValue3() {
      return this.selectedApplyData.n3 / this.selectedApplyData.c3 * 100;
    },
    getValue4() {
      return this.selectedApplyData.n4 / this.selectedApplyData.c4 * 100;
    },
    getValue8() {
      return this.selectedApplyData.n8 / this.selectedApplyData.c8 * 100;
    },
    getValue5() {
      return this.selectedApplyData.n5 / this.selectedApplyData.c5 * 100;
    },
    getValue6() {
      return this.selectedApplyData.n6 / this.selectedApplyData.c6 * 100;
    },
    getValue7() {
      return this.selectedApplyData.n7 / this.selectedApplyData.c7 * 100;
    },
    getValue8() {
      return this.selectedApplyData.n8 / this.selectedApplyData.c8 * 100;
    },
    getValue9() {
      return this.selectedApplyData.n5 / this.selectedApplyData.c9 * 100;
    },
    onLoadAgency() {
      var self = this;
      var sref = firebase.database().ref("user/");

      sref.once("value", function(sdata) {
        sdata.forEach(function(sdatas) {
          if (sdatas.val().isAgencey == "T") {
            self.agency.push(sdatas.val().uid);
          }
        });
        self.onLoad3();
      });
    },
    onLoad3() {
      var self = this;
      var sref = firebase.database().ref("user/");

      sref.once("value", function(sdata) {
        sdata.forEach(function(sdatas) {
          if (sdatas.val().isAgencey == "3") {
            self.sam.push(sdatas.val().uid);
          }
        });
        self.onLoadCampaign();
      });
    },
    onLoadCampaign() {
      var self = this;
      var sref = firebase.database().ref("rank_apply/" + this.campaignId);

      this.$vs.loading();

      sref.once("value", function(sdata) {
        self.keyword = sdata.val().autokeyword;
        self.store = sdata.val().sname;

        self.selectedApplyData = sdata.val();
        self.selectedApplyData["keyword"] = sdata.val().autokeyword
          ? sdata.val().autokeyword
          : sdata.val().keyword;
        self.selectedApplyData["c8"] = sdata.val().c8 ? sdata.val().c8 : 0;
        self.selectedApplyData["c9"] = sdata.val().c9 ? sdata.val().c9 : 0;
        self.selectedApplyData["n1"] = 0;
        self.selectedApplyData["n2"] = 0;
        self.selectedApplyData["n3"] = 0;
        self.selectedApplyData["n4"] = 0;
        self.selectedApplyData["n5"] = 0;
        self.selectedApplyData["n6"] = 0;
        self.selectedApplyData["n7"] = 0;
        self.selectedApplyData["n8"] = 0;

        self.selectedApplyData["regpay"] = sdata.val().regpay
          ? sdata.val().regpay
          : "0";

        self.selectedApplyData["agency"] = self.agency.find(
          item => item == sdata.val().uid
        )
          ? "T"
          : "F";

        if (self.selectedApplyData["agency"] == "F") {
          self.selectedApplyData["agency"] = self.sam.find(
            item => item == sdata.val().uid
          )
            ? "3"
            : "F";
        }

        self.getIsAgency = self.selectedApplyData["agency"];

        self.initColumn(sdata.val().storetype);

        self.selectedApplyData["videopay"] = sdata.val().videopay
          ? sdata.val().videopay
          : null;

        allCampaign = new Map();

        self.selectedApplyData["p9"] = sdata.val().p9 ? sdata.val().p9 : null;

        self.selectedApplyData["original"] = sdata.val().original
          ? sdata.val().original
          : sdata.val().tp2;
        self.selectedApplyData["deposit"] = sdata.val().deposit
          ? sdata.val().deposit
          : "0";
        self.selectedApplyData["couponQty"] = sdata.val().couponQty
          ? sdata.val().couponQty
          : "0";

        self.selectedApplyData["couponPay"] = sdata.val().couponPay
          ? sdata.val().couponPay
          : "0";

        var _tmp = replaceAll(sdata.val().cid, " ", "").split(",");

        var _report = "";

        if (sdata.val().report) {
          _report = sdata.val().report.split(",")[0];
        }

        var _swapkeyword = sdata.val().autokeyword
          ? sdata.val().autokeyword
          : sdata.val().keyword;

        if (
          sdata.val().storetype.indexOf("네이버") >= 0 ||
          sdata.val().storetype.indexOf("자사몰") >= 0
        ) {
          self.onLoadAutoHistory(
            sdata.key,
            sdata.val().storetype,
            _report,
            _swapkeyword
          );
        } else if (sdata.val().storetype == ShoppingMall.COUPANG) {
          self.onLoadAutoHistoryCoupang(
            sdata.key,
            sdata.val().storetype,
            _report,
            _swapkeyword
          );
        } else if (sdata.val().storetype == ShoppingMall.TMON) {
          self.onLoadAutoHistoryTmon(
            sdata.key,
            sdata.val().storetype,
            _report,
            _swapkeyword
          );
        }

        self.stack = 0;

        for (var i = 0; i < _tmp.length; i++) {
          if (_tmp[i] != "") {
            self.stack++;
            self.onLoadCampaignContents(_tmp[i]);
          }
          if (self.stack == 0) {
            self.loadingtbl = false;
            self.$vs.loading.close();
          }
        }
      });
    },
    async onLoadCampaignContents(cid) {
      var db = firebase.firestore();
      var self = this;

      await db
        .collection("pugshop_mission_summary")
        .where("campaignId", "==", cid.toString())
        .get()
        .then(function(querySnapshot) {
          self.stack--;
          querySnapshot.forEach(function(doc) {
            var _data = doc.data().o;
            _data.forEach(item => {
              var _idx = 0;
              var __name = item.tname;
              var __naverId = item.naver_id;
              var _mcd = item.mall_cd;
              var __name2 = item.oname;
              var __name3 = "";

              if (_mcd == "0302") {
                __naverId = item.coupang_id;
              } else if (_mcd == "0303") {
                __naverId = item.cj_mall_id;
              } else if (_mcd == "0304") {
                __naverId = item.eleven_st_id;
              } else if (_mcd == "0305") {
                __naverId = item.gmarket_id;
              } else if (_mcd == "0398") {
                if (item.evidence_orderer_name) {
                  var _evidence = item.evidence_orderer_name
                    ? item.evidence_orderer_name.split(",")
                    : [""];

                  __name3 =
                    item.evidence_orderer_name.indexOf(",") > 0
                      ? _evidence[0]
                      : item.evidence_orderer_name;
                  __name2 =
                    item.evidence_orderer_name.indexOf(",") > 0
                      ? _evidence[1]
                      : "";
                  __name = item.oname;
                }
              }

              var __regDate = item.mod_date
                ? getCurrentTime(new Date(item.mod_date.seconds * 1000))
                : "";

              var __applyStateCd = item.applier_mission_state_cd;
              var __applyDesc = "";

              if (__applyStateCd == "0901") {
                __applyDesc = "완료";
                _idx = 1;
              } else if (__applyStateCd == "0902") {
                __applyDesc = "미진행";
              } else if (__applyStateCd == "0903") {
                __applyDesc = "삭제";
              } else if (__applyStateCd == "0904") {
                __applyDesc = "확인중";
              }

              if (__applyDesc == "삭제") {
                //skip
              } else {
                var _item = {
                  date: __regDate,
                  sdate: __regDate,
                  owner:
                    item.mtype == "0503"
                      ? __name + "[" + __name2 + "]"
                      : __name,
                  id: __naverId + "_[" + item.applier_id + "]",
                  status: __applyDesc,
                  blogId: item.naver_blog_id,
                  blogCount: item.naver_blog_count,
                  blogIdRef: "https://blog.naver.com/" + item.naver_blog_id,
                  instaRef: "https://www.instagram.com/" + item.instagram_id,
                  instaId: item.instagram_id,
                  instaCount: item.instagram_count,
                  name: __name3,
                  mallCd: _mcd
                };

                if (item.mtype == "0503") {
                  var _dump = allCampaign.get(_item.id);

                  if (_dump) {
                    //overwrite
                    _dump[0] = {
                      obj: _item
                    };
                    allCampaign.set(_item.id, _dump);
                  } else {
                    var _dummy_ = [
                      {
                        obj: _item
                      },
                      {},
                      {},
                      {},
                      {},
                      {},
                      {},
                      {}
                    ];
                    allCampaign.set(_item.id, _dummy_);
                    //set
                  }

                  self.selectedApplyData.n1 += _idx;

                  //예외 케이스 캠페인 생성중 회원가입 미생성으로 인해 보정
                  // cid == "7471" || cid == "7478" ||
                  if (cid == "7563" || cid == "7471") {
                    if (self.selectedApplyData.n5 < 85) {
                      var _dump = allCampaign.get(_item.id);

                      if (_dump) {
                        //overwrite
                        _dump[4] = {
                          obj: _item
                        };
                        allCampaign.set(_item.id, _dump);
                      } else {
                        var _dummy_ = [
                          {},
                          {},
                          {},
                          {},
                          {
                            obj: _item
                          },
                          {},
                          {},
                          {}
                        ];
                        allCampaign.set(_item.id, _dummy_);
                        //set
                      }

                      self.selectedApplyData.n5 += _idx;
                    }
                  }
                } else if (item.mtype == "0504") {
                  var _dump = allCampaign.get(_item.id);

                  if (_dump) {
                    //overwrite
                    _dump[1] = {
                      obj: _item
                    };
                    allCampaign.set(_item.id, _dump);
                  } else {
                    var _dummy_ = [
                      {},
                      {
                        obj: _item
                      },
                      {},
                      {},
                      {},
                      {},
                      {},
                      {}
                    ];
                    allCampaign.set(_item.id, _dummy_);
                    //set
                  }

                  self.selectedApplyData.n2 += _idx;
                } else if (item.mtype == "0505") {
                  var _dump = allCampaign.get(_item.id);

                  if (_dump) {
                    //overwrite
                    _dump[2] = {
                      obj: _item
                    };
                    allCampaign.set(_item.id, _dump);
                  } else {
                    var _dummy_ = [
                      {},
                      {},
                      {
                        obj: _item
                      },
                      {},
                      {},
                      {},
                      {},
                      {}
                    ];
                    allCampaign.set(_item.id, _dummy_);
                    //set
                  }

                  self.selectedApplyData.n3 += _idx;
                } else if (item.mtype == "0501") {
                  var _dump = allCampaign.get(_item.id);

                  if (_dump) {
                    //overwrite
                    _dump[3] = {
                      obj: _item
                    };
                    allCampaign.set(_item.id, _dump);
                  } else {
                    var _dummy_ = [
                      {},
                      {},
                      {},
                      {
                        obj: _item
                      },
                      {},
                      {},
                      {},
                      {}
                    ];
                    allCampaign.set(_item.id, _dummy_);
                    //set
                  }

                  self.selectedApplyData.n4 += _idx;
                } else if (item.mtype == "0502") {
                  var _dump = allCampaign.get(_item.id);

                  if (_dump) {
                    //overwrite
                    _dump[4] = {
                      obj: _item
                    };
                    allCampaign.set(_item.id, _dump);
                  } else {
                    var _dummy_ = [
                      {},
                      {},
                      {},
                      {},
                      {
                        obj: _item
                      },
                      {},
                      {},
                      {}
                    ];
                    allCampaign.set(_item.id, _dummy_);
                    //set
                  }

                  self.selectedApplyData.n5 += _idx;
                } else if (item.mtype == "0507") {
                  var _dump = allCampaign.get(_item.id);

                  if (_dump) {
                    //overwrite
                    _dump[5] = {
                      obj: _item
                    };
                    allCampaign.set(_item.id, _dump);
                  } else {
                    var _dummy_ = [
                      {},
                      {},
                      {},
                      {},
                      {},
                      {
                        obj: _item
                      },
                      {},
                      {}
                    ];
                    allCampaign.set(_item.id, _dummy_);
                    //set
                  }

                  self.selectedApplyData.n6 += _idx;
                } else if (item.mtype == "0506") {
                  var _dump = allCampaign.get(_item.id);

                  if (_dump) {
                    //overwrite
                    _dump[6] = {
                      obj: _item
                    };
                    allCampaign.set(_item.id, _dump);
                  } else {
                    var _dummy_ = [
                      {},
                      {},
                      {},
                      {},
                      {},
                      {},
                      {
                        obj: _item
                      },
                      {}
                    ];
                    allCampaign.set(_item.id, _dummy_);
                    //set
                  }

                  self.selectedApplyData.n7 += _idx;
                } else if (item.mtype == "0508") {
                  var _dump = allCampaign.get(_item.id);

                  if (_dump) {
                    //overwrite
                    _dump[7] = {
                      obj: _item
                    };
                    allCampaign.set(_item.id, _dump);
                  } else {
                    var _dummy_ = [
                      {},
                      {},
                      {},
                      {},
                      {},
                      {},
                      {},
                      {
                        obj: _item
                      }
                    ];
                    allCampaign.set(_item.id, _dummy_);
                    //set
                  }

                  self.selectedApplyData.n8 += _idx;
                }
              }
            });
          });
        });

      if (self.stack <= 0) {
        this.onMakeCampaignList();
      }
    },
    onMakeCampaignList() {
      var idx = 1;
      var self = this;
      allCampaign.forEach(item => {
        var _dummy = item;
        var _d = {
          index: idx++,
          id:
            _dummy[0].obj.mallCd == "0398"
              ? _dummy[0].obj.name
              : _dummy[0].obj.id,
          name: _dummy[0].obj.owner,
          date: _dummy[0].obj.date,
          c1: _dummy[0].obj.status,
          c2: _dummy[1].obj ? _dummy[1].obj.status : "-",
          c3: _dummy[2].obj ? _dummy[2].obj.status : "-",
          c4: _dummy[3].obj ? _dummy[3].obj.status : "-",
          c5: _dummy[4].obj ? _dummy[4].obj.status : "-",
          c8: _dummy[7].obj ? _dummy[7].obj.status : "-",

          c7: _dummy[6].obj ? _dummy[6].obj.status : "-",
          c6: _dummy[5].obj ? _dummy[5].obj.status : "-",

          c1date: _dummy[0].obj ? _dummy[0].obj.sdate : "-",
          c2date: _dummy[1].obj ? _dummy[1].obj.sdate : "-",
          c3date: _dummy[2].obj ? _dummy[2].obj.sdate : "-",
          c4date: _dummy[3].obj ? _dummy[3].obj.sdate : "-",

          c5date: _dummy[4].obj ? _dummy[4].obj.sdate : "-",
          c8date: _dummy[7].obj ? _dummy[7].obj.sdate : "-",

          c7date: _dummy[6].obj ? _dummy[6].obj.sdate : "-",
          c6date: _dummy[5].obj ? _dummy[5].obj.sdate : "-"
        };
        self.campaigndata1.push(_d);
      });
      self.loadingtbl = false;
      self.$vs.loading.close();
    },
    onLoadHistory(key, storetype, reportkey) {
      var self = this;
      var _tblname = "execution_master/";
      if (storetype == "쿠팡") {
        _tblname = "execution_master_coupang/";
      } else if (storetype == "티몬") {
        _tblname = "execution_master_tmon/";
      }

      var sref = firebase.database().ref(_tblname);
      sref
        .orderByChild("report")
        .equalTo(reportkey)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            var _item = {};

            var _ad = "F";
            if (sdatas.child("ad").exists()) {
              _ad = sdatas.val().ad;
            }
            _item["id"] = sdatas.key;
            _item["ad"] = _ad == "F" ? "-" : _ad;

            _item["mallId"] = sdatas.val().mallId;
            _item["title"] = sdatas.val().productTitle;
            _item["keyword"] = sdatas.val().keyword;
            _item["productid"] = sdatas.val().productId;
            _item["href"] = sdatas.val().ahref;
            _item["previous"] = "";
            _item["position"] = "";
            _item["rawdata"] = undefined;
            _item["data"] = [
              {
                name: ["전체 순위"],
                data: []
              }
            ];

            var _keyword_ = self.baseKeyword
              ? self.baseKeyword
              : sdatas.val().keyword;

            self.chartRawData = [
              {
                name: _keyword_,
                data: []
              }
            ];
            _item["option"] = self.chartOptions;

            _item["rawdata"] = [];

            var _idx = 0;

            if (storetype == "쿠팡") {
              self.autoHistoryCoupang.forEach(item => {
                if (
                  (item.productId == _item["productid"] &&
                    item.keyword == _item["keyword"] &&
                    item.ad == _item["ad"] &&
                    self.baseKeyword == undefined) ||
                  self.baseKeyword != undefined
                ) {
                  _item["rawdata"].push(item);

                  var innerArr = [item.eventtime, item.cumrank];
                  var _desc = item.page + "페이지내 " + item.rank + "위";

                  self.chartRawData[0].data.push(innerArr);

                  if (_idx == 0) {
                    _item["position"] = item.cumrank;
                  }

                  _idx++;
                }
              });
            } else if (storetype == "티몬") {
              self.autoHistoryTmon.forEach(item => {
                if (
                  item.productId == _item["productid"] &&
                  item.keyword == _item["keyword"] &&
                  item.ad == _item["ad"]
                ) {
                  _item["rawdata"].push(item);
                  var innerArr = [item.eventtime, item.cumrank];
                  var _desc = item.page + "페이지내 " + item.rank + "위";

                  self.chartRawData[0].data.push(innerArr);

                  if (_idx == 0) {
                    _item["position"] = item.cumrank;
                  }

                  _idx++;
                }
              });
            } else {
              self.autoHistoryNaver.forEach(item => {
                if (
                  (item.productId.toString() == _item["productid"].toString() &&
                    item.keyword == _item["keyword"] &&
                    item.ad == _item["ad"] &&
                    self.baseKeyword == undefined) ||
                  self.baseKeyword != undefined
                ) {
                  _item["rawdata"].push(item);
                  var innerArr = [item.eventtime, item.cumrank];
                  var _desc = item.page + "페이지내 " + item.rank + "위";

                  self.chartRawData[0].data.push(innerArr);

                  if (_idx == 0) {
                    _item["position"] = item.cumrank;
                  }

                  _idx++;
                }
              });
            }
          });
          self.$vs.loading.close();
          self.loadingtbl = false;
        });
    },
    onLoadAutoHistory(_key, _storetype, _reportkey, _keyword) {
      //Improve Query Speed
      var sref = firebase.database().ref("auto_execution_history_report/");
      var _previousDay = "0";
      if (_reportkey) {
        this.baseKeyword = _keyword;
        var self = this;
        var __tmp__ = [];
        sref
          .orderByChild("val3")
          .equalTo(_reportkey)
          .once("value", function(sdata) {
            sdata.forEach(function(sdatas) {
              var _ad = sdatas.val().val1 == "F" ? "-" : sdatas.val().val1;
              // if (sdatas.val().keyword == _keyword || _keyword == "") {
              var _item = {
                key: sdatas.key,
                productId: sdatas.val().productId,
                keyword: sdatas.val().keyword,
                ad: _ad,
                cumrank: sdatas.val().cumrank,
                page: sdatas.val().page,
                rank: sdatas.val().rank,
                eventtime: sdatas.val().eventdttm
              };

              if (_previousDay == sdatas.val().eventdttm.substr(0, 10)) {
                //skip
              } else {
                _previousDay = sdatas.val().eventdttm.substr(0, 10);
                __tmp__.push(_item);
              }
              // }
            });

            self.autoHistoryNaver = __tmp__.sort(function(a, b) {
              // desc
              return a.eventtime > b.eventtime
                ? -1
                : a.eventtime < b.eventtime ? 1 : 0;
            });

            self.onLoadHistory(_key, _storetype, _reportkey);
            //sort
          });
      }
    },

    onLoadAutoHistoryCoupang(_key, _storetype, _reportkey, _keyword) {
      var sref = firebase
        .database()
        .ref("auto_execution_history_coupang_report/");
      if (_reportkey) {
        this.baseKeyword = _keyword;
        var self = this;
        var __tmp__ = [];
        sref
          .orderByChild("val3")
          .equalTo(_reportkey)
          .once("value", function(sdata) {
            sdata.forEach(function(sdatas) {
              var _ad = sdatas.val().val1 == "F" ? "-" : sdatas.val().val1;
              if (sdatas.val().keyword == _keyword || _keyword == undefined) {
                var _item = {
                  key: sdatas.key,
                  productId: sdatas.val().productId,
                  keyword: sdatas.val().keyword,
                  ad: _ad,
                  cumrank: sdatas.val().cumrank,
                  page: sdatas.val().page,
                  rank: sdatas.val().rank,
                  eventtime: sdatas.val().eventdttm,
                  duplicate:
                    sdatas.val().productId +
                    sdatas.val().keyword +
                    sdatas.val().ad +
                    sdatas.val().eventdttm.substr(0, 10)
                };

                if (__tmp__.find(o => o.duplicate == _item.duplicate)) {
                  //skip
                } else {
                  __tmp__.push(_item);
                }
              }
            });

            self.autoHistoryCoupang = __tmp__.sort(function(a, b) {
              // desc
              return a.eventtime > b.eventtime
                ? -1
                : a.eventtime < b.eventtime ? 1 : 0;
            });
            self.onLoadHistory(_key, _storetype, _reportkey);

            //sort
          });
      }
    },
    onLoadAutoHistoryTmon(_key, _storetype, _reportkey, _keyword) {
      var sref = firebase.database().ref("auto_execution_history_tmon_report/");
      if (_reportkey) {
        var self = this;
        var __tmp__ = [];
        sref
          .orderByChild("val3")
          .equalTo(_reportkey)
          .once("value", function(sdata) {
            sdata.forEach(function(sdatas) {
              var _ad = sdatas.val().val1 == "F" ? "-" : sdatas.val().val1;

              var _item = {
                key: sdatas.key,
                productId: sdatas.val().productId,
                keyword: sdatas.val().keyword,
                ad: _ad,
                cumrank: sdatas.val().cumrank,
                page: sdatas.val().page,
                rank: sdatas.val().rank,
                eventtime: sdatas.val().eventdttm
              };
              __tmp__.push(_item);
            });

            self.autoHistoryTmon = __tmp__.sort(function(a, b) {
              // desc
              return a.eventtime > b.eventtime
                ? -1
                : a.eventtime < b.eventtime ? 1 : 0;
            });
            self.onLoadHistory(_key, _storetype, _reportkey);

            //sort
          });
      }
    },
    initColumn(storetype) {
      if (storetype == ShoppingMall.NAVER_PLACE) {
        this.columns = [
          {
            dataIndex: "index",
            key: "index",
            title: "연번",
            width: 90
          },
          {
            dataIndex: "id",
            key: "id",
            title: "아이디",
            width: 200
          },
          {
            dataIndex: "name",
            key: "name",
            title: "이름",
            width: 200
          },
          {
            key: "c1",
            title: "네이버예약상태",
            width: 200,
            scopedSlots: {
              customRender: "c1"
            }
          },
          {
            key: "c2",
            title: "영수증리뷰",
            width: 200,
            scopedSlots: {
              customRender: "c2"
            }
          },
          {
            key: "c4",
            title: "플레이스저장",
            width: 200,
            scopedSlots: {
              customRender: "c4"
            }
          },
          {
            key: "c7",
            title: "인스타그램",
            width: 200,
            scopedSlots: {
              customRender: "c7"
            }
          },
          {
            key: "c6",
            title: "네이버블로그",
            width: 200,
            scopedSlots: {
              customRender: "c6"
            }
          }
        ];
      } else if (storetype == ShoppingMall.WADIZ) {
        this.columns = [
          {
            dataIndex: "index",
            key: "index",
            title: "연번",
            width: 90
          },
          {
            dataIndex: "id",
            key: "id",
            title: "주문자",
            width: 200
          },
          {
            dataIndex: "name",
            key: "name",
            title: "퍼그샵[펀딩번호]",
            width: 200
          },
          {
            key: "c1",
            title: "펀딩하기상태",
            width: 200,
            scopedSlots: {
              customRender: "c1"
            }
          },
          {
            key: "c2",
            title: "응원리뷰",
            width: 200,
            scopedSlots: {
              customRender: "c2"
            }
          },
          {
            key: "c4",
            title: "찜하기",
            width: 200,
            scopedSlots: {
              customRender: "c4"
            }
          },
          {
            key: "c5",
            title: "지지서명하기",
            width: 200,
            scopedSlots: {
              customRender: "c5"
            }
          },
          {
            key: "c7",
            title: "인스타그램",
            width: 200,
            scopedSlots: {
              customRender: "c7"
            }
          },
          {
            key: "c6",
            title: "네이버블로그",
            width: 200,
            scopedSlots: {
              customRender: "c6"
            }
          }
        ];
      } else {
        this.columns = [
          {
            dataIndex: "index",
            key: "index",
            title: "연번",
            width: 90
          },
          {
            dataIndex: "id",
            key: "id",
            title: "아이디",
            width: 200
          },
          {
            dataIndex: "name",
            key: "name",
            title: "이름",
            width: 200
          },
          {
            key: "c1",
            title: "구매상태",
            width: 200,
            scopedSlots: {
              customRender: "c1"
            }
          },
          {
            key: "c2",
            title: "텍스트리뷰",
            width: 200,
            scopedSlots: {
              customRender: "c2"
            }
          },
          {
            key: "c3",
            title: "포토리뷰",
            width: 200,
            scopedSlots: {
              customRender: "c3"
            }
          },
          {
            key: "c4",
            title: "찜",
            width: 200,
            scopedSlots: {
              customRender: "c4"
            }
          },
          {
            key: "c5",
            title: "Q&A",
            width: 200,
            scopedSlots: {
              customRender: "c5"
            }
          },
          {
            key: "c8",
            title: "동영상리뷰",
            width: 200,
            scopedSlots: {
              customRender: "c8"
            }
          },
          {
            key: "c9",
            title: "회원가입",
            width: 200,
            scopedSlots: {
              customRender: "c9"
            }
          },
          {
            key: "c7",
            title: "인스타그램",
            width: 200,
            scopedSlots: {
              customRender: "c7"
            }
          },
          {
            key: "c6",
            title: "네이버블로그",
            width: 200,
            scopedSlots: {
              customRender: "c6"
            }
          }
        ];
      }
      if (storetype == ShoppingMall.NAVER_PLACE && this.getIsAgency == "T") {
        this.buypay = "5000";
      } else {
        if (this.getIsAgency == "T") {
          this.buypay = "4000";
        } else if (this.getIsAgency == "3") {
          this.buypay = "3000";
        } else if (this.getIsAgency == "2.5") {
          this.buypay = "2500";
        } else if (storetype == ShoppingMall.NAVER_PLACE) {
          this.buypay = "10000";
        } else if (storetype == ShoppingMall.WADIZ) {
          this.buypay = "7000";
        } else {
          this.buypay = "5000";
        }
      }

      if (storetype == ShoppingMall.NAVER_PLACE) {
        this.textpay = "0";
      } else {
        this.textpay =
          this.getIsAgency == "T" ||
          this.getIsAgency == "3" ||
          this.getIsAgency == "2.5"
            ? "0"
            : "1000";
      }

      this.photopay =
        this.getIsAgency == "T" ||
        this.getIsAgency == "3" ||
        this.getIsAgency == "2.5"
          ? "0"
          : "2000";

      this.videopay = "5000";

      if (storetype == ShoppingMall.NAVER_PLACE) {
        this.zzimpay = "0";
      } else {
        this.zzimpay =
          this.getIsAgency == "T" ||
          this.getIsAgency == "3" ||
          this.getIsAgency == "2.5"
            ? "0"
            : "1000";
      }

      if (storetype == ShoppingMall.WADIZ) {
        this.qapay = "2000";
      } else {
        this.qapay =
          this.getIsAgency == "T" ||
          this.getIsAgency == "3" ||
          this.getIsAgency == "2.5"
            ? "0"
            : storetype == ShoppingMall.WADIZ ? "2000" : "1000";
      }

      if (storetype.substr(0, 3) == "자사몰") {
        this.regpay = "1000";
      }
    },
    initStoreList() {
      for (var i = 0; i < this.storelist.length; i++) {
        this.storelist[i]["missionTitle"] = {
          c1: "구매",
          c2: "텍스트리뷰",
          c3: "포토리뷰",
          c4: "찜",
          c5: "Q&A",
          c8: "동영상리뷰",
          c9: "회원가입"
        };
      }

      //Naver Place
      this.storelist[4]["missionTitle"] = {
        c1: "네이버예약",
        c2: "영수증리뷰",
        c3: "",
        c4: "플레이스저장",
        c5: "",
        c8: "",
        c9: ""
      };

      // //Wadiz
      this.storelist[15]["missionTitle"] = {
        c1: "펀딩",
        c2: "응원리뷰",
        c3: "",
        c4: "찜",
        c5: "지지서명",
        c8: "",
        c9: ""
      };
    },
    onExportExcelByCampaign() {
      var date = new Date();
      var self = this;
      var e1 = [];

      var _column = [];

      this.columns.forEach(item => {
        _column.push(item.title);
      });
      e1.push(_column);

      var wb = XLSX.utils.book_new();

      this.campaigndata1.forEach(item => {
        var _item = [];
        _item.push(item.index);
        _item.push(item.id);
        _item.push(item.name);
        if (this.selectedApplyData["c1"]) {
          var _c1 = item.c1 == "완료" ? "✓완료[" + item.c1date + "]" : item.c1;
          _item.push(_c1);
        }
        if (this.selectedApplyData["c2"]) {
          var _c2 = item.c2 == "완료" ? "✓완료[" + item.c2date + "]" : item.c2;
          _item.push(_c2);
        }
        if (this.selectedApplyData["c3"]) {
          var _c3 = item.c3 == "완료" ? "✓완료[" + item.c3date + "]" : item.c3;
          _item.push(_c3);
        } else {
          _item.push("");
        }
        if (this.selectedApplyData["c4"]) {
          var _c4 = item.c4 == "완료" ? "✓완료[" + item.c4date + "]" : item.c4;

          _item.push(_c4);
        } else {
          _item.push("");
        }
        if (this.selectedApplyData["c5"]) {
          var _c5 = item.c5 == "완료" ? "✓완료[" + item.c5date + "]" : item.c5;
          _item.push(_c5);
        } else {
          _item.push("");
        }

        if (this.selectedApplyData["c8"]) {
          var _c8 = item.c8 == "완료" ? "✓완료[" + item.c8date + "]" : item.c8;
          _item.push(_c8);
        } else {
          _item.push("");
        }

        if (this.selectedApplyData["c9"]) {
          var _c5 = item.c5 == "완료" ? "✓완료[" + item.c5date + "]" : item.c5;
          _item.push(_c5);
        } else {
          _item.push("");
        }

        if (this.selectedApplyData["c7"]) {
          var _c7 = item.c7 == "완료" ? "✓완료[" + item.c7date + "]" : item.c7;
          _item.push(_c7);
        } else {
          _item.push("");
        }

        if (this.selectedApplyData["c6"]) {
          var _c6 = item.c6 == "완료" ? "✓완료[" + item.c6date + "]" : item.c6;
          _item.push(_c6);
        } else {
          _item.push("");
        }

        e1.push(_item);
      });

      var ws1 = XLSX.utils.aoa_to_sheet(e1);

      XLSX.utils.book_append_sheet(wb, ws1, "캠페인 이력");

      /* get binary string as output */
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array"
      });

      try {
        FileSaver.saveAs(
          new Blob([wbout], {
            type: "application/octet-stream"
          }),
          "스토어링크-캠페인." + date.getTime() + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    }
  },
  mounted() {
    this.campaignId = this.$route.query.cid;
    this.loadingtbl = true;
    this.initStoreList();
    this.onLoadAgency();
    fbq("track", "PageView");
  }
};
</script>

<style>
.s-r-b1 {
  height: 100%;
  border-radius: 12px;
  margin-right: 24px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  padding: 20px;
}

.s-r-b1.mobile {
  margin-right: 0px;
  margin-bottom: 10px;
}

.s-r-b2 {
  height: 100%;
  border-radius: 12px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
}

.s-r-b3 {
  width: 100px;
  height: 30px;
  padding: 3px 7px 4px 8px;
  border-radius: 5px;
  border: solid 1px #7d7d7d;
  float: right;
  margin-top: -25px;
  text-align: center;
}

.s-r-b3 > a > span {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #7d7d7d;
}

.s-r-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.74px;
  color: #000000;
  margin-bottom: 16px;
}

.s-r-font2 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #7d7d7d;
}

.s-r-font3 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.25px;
  color: #000000;
}

.s-r-font4 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.25px;
  color: #0264fb;
}

.s-r-font4 > span {
  font-family: "Muli";
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.25px;
  color: #0264fb;
}

.s-r-font5 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #7d7d7d;
}

.s-r-font6 {
  font-family: "Muli";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #0264fb;
}

.s-r-font7 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
}

.s-r-font8 {
  font-family: "Muli";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #e02020;
}

.s-r-font9 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  text-align: center;
  color: #0264fb;
}

.s-r-font10 {
  font-family: "Muli";
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.75px;
  color: #7d7d7d;
}

.s-r-font11 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  text-align: center;
  color: #e02020;
}

.s-r-font12 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.59px;
  color: #000000;
}

.report .ant-tabs-tab {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #7d7d7d;
}

.report .ant-tabs-tab:hover {
  color: #0264fb !important;
}

.report .ant-tabs-tab-active {
  color: #0264fb !important;
}

.report .ant-tabs-ink-bar {
  background-color: #0264fb !important;
  height: 4px !important;
}
</style>
